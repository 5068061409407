import {type PepIconName} from '@calvium/pep-icons';
import {z} from 'zod';
import {CategorySchema} from './category.schema';

export const SightCategorySchema = CategorySchema.extend({
  sightIds: z.string().array().optional(),
});

export type SightCategory = z.infer<typeof SightCategorySchema> & {
  pepIcon?: PepIconName;
};
