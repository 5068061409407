import {z} from 'zod';

const uiStringItem = (defaultString: string) => z.string().default(defaultString);

export const UIStringsSchema = z.object({
  amenities: z
    .object({
      title: uiStringItem('Amenities'),
    })
    .default({}),
  events: z
    .object({
      addToCalendar: uiStringItem('Add to calendar'),
      allEvents: uiStringItem('All Events'),
      calendar: uiStringItem('Calendar'),
      calendarApple: uiStringItem('Apple'),
      calendarGoogle: uiStringItem('Google'),
      calendarOutlook: uiStringItem('Outlook'),
      calendarYahoo: uiStringItem('Yahoo'),
      defaultBreadcrumbEvent: uiStringItem('event'),
      defaultBreadcrumbEvents: uiStringItem('events'),
      eventCategories: uiStringItem('Event Categories'),
      fallbackImageAlt: uiStringItem('event image'),
      previewShowAllButtonLabel: uiStringItem('Show all events'),
      previewShowOnMapButtonLabel: uiStringItem('Show events on map'),
      previewTitle: uiStringItem('Upcoming events'),
      relatedEvents: uiStringItem('Related events'),
      relatedSight: uiStringItem('Event venue'),
      searchPlaceholder: uiStringItem('Search Events'),
      selectDate: uiStringItem('Select date'),
      selectMonth: uiStringItem('Select a month'),
      timeFrame: uiStringItem('Time Frame'),
      title: uiStringItem('Events'),
      tooltipWithEmphasisAmenityCategoryFilter: uiStringItem('Show events with **all** of these amenities'),
      tooltipWithEmphasisCalendarFilter: uiStringItem('Show events within a selected time frame or time range'),
      tooltipWithEmphasisEventCategoryFilter: uiStringItem('Show events with **any** of these categories'),
      tooltipWithEmphasisPlaceFilter: uiStringItem('Show events in a selected area and radius'),
    })
    .default({}),
  general: z
    .object({
      clearAll: uiStringItem('Clear all'),
      timeframeNames: z
        .object({
          nextWeekend: uiStringItem('Next Weekend'),
          thisWeekend: uiStringItem('This Weekend'),
          today: uiStringItem('Today'),
          tomorrow: uiStringItem('Tomorrow'),
        })
        .default({}),
      monthNames: z
        .object({
          april: uiStringItem('April'),
          august: uiStringItem('August'),
          december: uiStringItem('December'),
          february: uiStringItem('February'),
          january: uiStringItem('January'),
          july: uiStringItem('July'),
          june: uiStringItem('June'),
          march: uiStringItem('March'),
          may: uiStringItem('May'),
          november: uiStringItem('November'),
          october: uiStringItem('October'),
          september: uiStringItem('September'),
        })
        .default({}),
      clearSearch: uiStringItem('clear'),
      explore: uiStringItem('Explore'),
      filterTitle: uiStringItem('Filter'),
      gridButtonLabel: uiStringItem('Grid view'),
      locationAll: uiStringItem('All'),
      locationRadius: uiStringItem('Radius'),
      locationTitle: uiStringItem('Location'),
      mapButtonLabel: uiStringItem('Map view'),
      mobileAppButtonLabel: uiStringItem(`Learn more about 'Explore Wiltshire' app`),
      otherCategories: uiStringItem('Other'),
      radiusOption10Miles: uiStringItem('10 miles'),
      radiusOption20Miles: uiStringItem('20 miles'),
      radiusOption2Miles: uiStringItem('2 miles'),
      radiusOption30Miles: uiStringItem('30 miles'),
      radiusOption50Miles: uiStringItem('50 miles'),
      radiusOption5Miles: uiStringItem('5 miles'),
      searchFound: uiStringItem('found'),
      searchNoResults: uiStringItem('No results found'),
      searchPlaceholder: uiStringItem('Search'),
      searchResult: uiStringItem('result'),
      searchResults: uiStringItem('results'),
      skipToMainContent: uiStringItem('Skip to main content'),
      sideBar: z
        .object({
          events: uiStringItem('Events'),
          home: uiStringItem('Home'),
          more: uiStringItem('More'),
          places: uiStringItem('Places'),
          search: uiStringItem('Search'),
          sights: uiStringItem('Venues'),
          stopPreview: uiStringItem('Stop preview mode'),
        })
        .default({}),
    })
    .default({}),
  links: z
    .object({
      defaultLabel: uiStringItem('Find out more'),
    })
    .default({}),
  places: z
    .object({
      fallbackImageAlt: uiStringItem('place image'),
      previewShowAllButtonLabel: uiStringItem('Show all places'),
      previewShowOnMapButtonLabel: uiStringItem('Show places on map'),
      previewTitle: uiStringItem('Preview'),
      title: uiStringItem('Places'),
    })
    .default({}),
  sights: z
    .object({
      defaultBreadcrumbSight: uiStringItem('sight'),
      defaultBreadcrumbSights: uiStringItem('sights'),
      fallbackImageAlt: uiStringItem('sight image'),
      previewShowAllButtonLabel: uiStringItem('Show all sights'),
      previewShowOnMapButtonLabel: uiStringItem('Show sights on map'),
      previewTitle: uiStringItem('Sights'),
      relatedSights: uiStringItem('Related sights'),
      searchPlaceholder: uiStringItem('Search Venues'),
      sightCategories: uiStringItem('Sight Categories'),
      title: uiStringItem('Sights'),
    })
    .default({}),
  more: z
    .object({
      creditsTitle: uiStringItem('Web App Credits'),
      feedback: z
        .object({
          title: uiStringItem('Feedback'),
          feedbackLinkLabel: uiStringItem('Feedback form'),
          feedbackEmailLabel: uiStringItem('Send feedback via email'),
        })
        .default({
          title: 'Feedback',
          feedbackLinkLabel: 'Submit feedback via our feedback form',
          feedbackEmailLabel: 'Submit feedback via email',
        }),
      themeTitle: uiStringItem('Theme'),
      title: uiStringItem('More'),
    })
    .default({}),
});

export const defaultUIStrings = UIStringsSchema.parse({});

export type UIStrings = z.output<typeof UIStringsSchema>;
