'use client';

import classNames from 'classnames';
import {type ComponentProps} from 'react';
import {useCurrentThemeVariant, useThemeStore} from '../store/themeColorsStore';

export function Theme({hasDarkMode, className, ...props}: ComponentProps<'div'> & {hasDarkMode: boolean}) {
  const variant = useCurrentThemeVariant();
  if (!hasDarkMode && variant === 'dark') {
    useThemeStore.setState({themeColorsVariant: 'light'});
  }
  return <div className={classNames(variant === 'dark' && 'dark', className)} {...props} />;
}
