import {type PepIconName} from '@calvium/pep-icons';
import {z} from 'zod';

/**
 * Generic category schema.
 * This is used as a base for other category schemas.
 */
export const CategorySchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  pepIcon: z.string().optional(),
  class: z.string().optional(),
});

export type Category = z.infer<typeof CategorySchema> & {
  pepIcon?: PepIconName;
};
