import {create} from 'zustand';
import {persist} from 'zustand/middleware';

const cookieGroupNames = ['functional', 'analytics'] as const;
export type CookieGroupName = (typeof cookieGroupNames)[number];

type CookieConsent = Record<CookieGroupName, boolean> & {
  accepted: boolean;
  acceptAll: () => void;
  acceptCookies: (groupNames: CookieGroupName[]) => void;
  rejectAll: () => void;
};

const useCookieConsentStore = create<CookieConsent>()(
  persist(
    (set, get) => ({
      accepted: false,
      functional: false,
      analytics: false,
      acceptCookies: (groupNames: CookieGroupName[]) => {
        for (const groupName of groupNames) {
          set({[groupName]: true});
        }
        const notAcceptedGroups = cookieGroupNames.filter(g => !groupNames.includes(g));
        for (const groupName of notAcceptedGroups) {
          set({[groupName]: false});
        }
        set({accepted: true});
      },
      acceptAll: () => {
        const {acceptCookies} = get();
        acceptCookies(cookieGroupNames as unknown as CookieGroupName[]);
      },
      rejectAll: () => {
        const {acceptCookies} = get();
        acceptCookies(['functional']);
      },
    }),
    {name: 'cookieConsent'}
  )
);

export default useCookieConsentStore;
