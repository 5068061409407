'use client';

import {type PlaceSummary} from '#/cms/schemas/place-summary.schema';
import {createStore} from 'zustand';
import getStoreProvider from './getStoreProvider';

type PlaceState = {
  placeMap: Map<string, PlaceSummary>;
};

const defaultInitState: PlaceState = {
  placeMap: new Map(),
};

const createPlaceStore = (initState: PlaceState = defaultInitState) => {
  return createStore<PlaceState>()(() => initState);
};

const {StateProvider, useCurrentStore} = getStoreProvider('PlaceStore', createPlaceStore);

export const PlaceStoreProvider = StateProvider;
export const usePlaceStore = useCurrentStore;
