'use client';

import {createStore} from 'zustand';
import {useFileUrl} from './clientEnvStore';
import {type WebAppInfo} from '#/cms/schemas/content.schema';
import getStoreProvider from './getStoreProvider';

const createWebAppInfoStore = (
  initState: WebAppInfo = {
    logoAlt: '',
  }
) => {
  return createStore<WebAppInfo>()(() => initState);
};

const {StateProvider, useCurrentStore} = getStoreProvider('WebAppInfoStore', createWebAppInfoStore);

export const WebAppInfoStoreProvider = StateProvider;
export const useWebAppInfoStore = useCurrentStore;

export const useAppLogo = (): {logoUrl: string; logoAlt: string} => {
  const {logo, logoAlt} = useWebAppInfoStore();
  const logoUrl = useFileUrl(logo);
  return {logoUrl, logoAlt};
};
