import {z} from 'zod';

export const HexColorSchema = z.string().min(4).max(7).regex(/^#/);

export const RgbaColorSchema = z.string().regex(/^rgba\(\d{1,3},\s\d{1,3},\s\d{1,3},\s0?\.\d+\)$/);

export const colorNames = [
  'primary',
  'secondary',
  'background',
  'foreground',
  'midground1',
  'midground2',
  'midground3',
  'midground4',
  'light',
  'dark',
  'modalBackdrop',
  'success',
  'danger',
] as const;

const colorNameSchema = z.enum(colorNames);

export type ColorName = (typeof colorNames)[number];

const ColorThemeSchema = z.record(colorNameSchema, HexColorSchema.or(RgbaColorSchema));

export type ColorTheme = z.infer<typeof ColorThemeSchema>;

const colorThemeVariants = ['light', 'dark'] as const;

export const ThemeColorsVariantSchema = z.enum(colorThemeVariants);

export const ThemeColorsSchema = z.object({
  light: ColorThemeSchema,
  dark: ColorThemeSchema,
});

export type ThemeColors = z.infer<typeof ThemeColorsSchema>;
export type ThemeColorsVariant = z.infer<typeof ThemeColorsVariantSchema>;
