'use client';

import {useCallback, useRef, useState} from 'react';
import {Dialog, Switch} from '@headlessui/react';
import Link from 'next/link';
import Box from './Box';
import classNames from 'classnames';
import useCookieConsentStore, {type CookieGroupName} from '../store/cookieConsentStore';
import {BodyText} from './Text';

type CookieGroups = Record<
  CookieGroupName,
  {
    name: string;
    description: string;
    enabled: boolean;
    readOnly: boolean;
  }
>;

// TODO: Add uiString for the button text
const cookieGroups: CookieGroups = {
  functional: {
    name: 'Functional',
    description:
      'These cookies are strictly necessary for the provision of the service you have expressly requested. These cookies will be automatically activated and cannot be deactivated, as they are necessary to enable you to browse our website.',
    enabled: true,
    readOnly: true,
  },
  analytics: {
    name: 'Analytics',
    description:
      'These cookies allow us to understand how you access our website and your usage habits (e.g. pages visited, time spent on a page, or content clicked on). They allow us to analyse the performance and design of our website and to detect possible errors.',
    enabled: false,
    readOnly: false,
  },
};

const noop = () => undefined; //do nothing

type CookieSelectModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const CookieSelectModal = ({isOpen, setIsOpen}: CookieSelectModalProps) => {
  const {acceptCookies} = useCookieConsentStore();
  const [groups, setGroups] = useState(cookieGroups);
  const boxes = useRef<CookieGroupName[]>(['functional', 'analytics']);
  const initialFocusRef = useRef(null);

  const handleGroupEnabledSwitch = useCallback((groupId: CookieGroupName) => {
    setGroups(groups => {
      const group = groups[groupId];
      if (group.readOnly) return groups;
      return {...groups, [groupId]: {...group, enabled: !group.enabled}};
    });
  }, []);

  const handleSavePreferences = useCallback(() => {
    const acceptedCookies: CookieGroupName[] = [];
    for (const [groupId, group] of Object.entries(groups)) {
      if (group.enabled) acceptedCookies.push(groupId as CookieGroupName);
    }
    acceptCookies(acceptedCookies);
    setIsOpen(false);
  }, [acceptCookies, groups, setIsOpen]);

  return (
    <Dialog className="relative z-50" open={isOpen} onClose={noop} initialFocus={initialFocusRef}>
      <div className="fixed inset-0 bg-foreground opacity-50" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="flex max-w-lg flex-col gap-y-3 rounded-2xl border-2 border-midground4 bg-background p-6">
          <Dialog.Title className="flex justify-between">
            <div className="text-[1.25rem] font-bold text-foreground">Configure your cookies</div>
          </Dialog.Title>
          <Dialog.Description className="flex flex-col gap-y-4">
            <BodyText ref={initialFocusRef}>
              Cookies are small text files that are placed on your device that we use to improve your experience on our
              website and to show you relevant advertising. The cookies installed on your device are detailed below. You
              can also manage the cookies that are placed on your device below. For more information, please refer to
              our{' '}
              <Link href={'#'} className="text-primary" aria-label="Cookie policy">
                Cookie Policy
              </Link>
              .
            </BodyText>
            {boxes.current.map(groupId => (
              <Box key={`$cookie-consent-box-${groupId}`}>
                <div className="flex flex-row">
                  <div className="flex flex-col gap-y-1">
                    <div className="text-[1.1rem] font-bold text-foreground">{groups[groupId].name}</div>
                    <BodyText>{groups[groupId].description}</BodyText>
                  </div>
                  <Switch
                    checked={groups[groupId].enabled}
                    disabled={groups[groupId].readOnly}
                    onChange={() => handleGroupEnabledSwitch(groupId)}
                    className={({checked}) =>
                      classNames(
                        'relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75',
                        checked ? 'border-primary bg-primary' : 'border-midground3 bg-midground2'
                      )
                    }
                  >
                    <span
                      aria-hidden="true"
                      className={`${groups[groupId].enabled ? 'translate-x-6' : 'translate-x-0'} pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-background shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
              </Box>
            ))}
          </Dialog.Description>
          <button
            className="me-auto mt-1 rounded-full bg-primary px-2 px-6 py-1 py-1.5 text-light"
            onClick={handleSavePreferences}
          >
            Save Preferences and Close
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const CookieConsentModal = () => {
  const {accepted, acceptAll, rejectAll} = useCookieConsentStore();
  const [selectorOpen, setSelectorOpen] = useState(false);

  const handleConfigureCookies = useCallback(() => {
    setSelectorOpen(true);
  }, []);

  return (
    <>
      <Dialog className="relative z-40" open={!accepted} onClose={noop}>
        <div className="fixed inset-0 bg-foreground opacity-50" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-end justify-end p-4">
          <Dialog.Panel className="flex max-w-sm flex-col gap-y-3 rounded-2xl border-2 border-midground4 bg-background p-6">
            <Dialog.Title className="flex justify-between">
              <div className="text-[1.1rem] font-bold text-foreground">Cookies</div>
              <button className="text-body text-primary" onClick={rejectAll}>
                <span aria-hidden>&gt;</span> Continue without accepting
              </button>
            </Dialog.Title>
            <Dialog.Description className={'text-foreground'}>
              We use cookies to analyse the use of the website and to show you advertising related to your preferences
              on the basis of a profile based on your browsing habits (e.g. pages visited).
              <Link href={'#'} className="text-primary">
                {` Read more. `}
              </Link>
              Do you accept cookies?
            </Dialog.Description>
            <div className="mt-2 flex flex-row gap-x-2">
              <button
                className="w-full rounded-full border border-primary bg-background px-2 py-1 text-foreground"
                onClick={handleConfigureCookies}
              >
                Configure
              </button>
              <button
                className="w-full rounded-full bg-primary px-2 py-1 text-light"
                onClick={acceptAll}
                autoFocus={true}
              >
                Accept
              </button>
            </div>
          </Dialog.Panel>
        </div>
        <CookieSelectModal tab-index={0} isOpen={selectorOpen} setIsOpen={setSelectorOpen} />
      </Dialog>
    </>
  );
};

export default CookieConsentModal;
