'use client';

import {type UIStrings, defaultUIStrings} from '#/cms/schemas/uiStrings.schema';
import {createStore} from 'zustand';
import getStoreProvider from './getStoreProvider';

type UIStringsState = UIStrings;

const createUiStringsStore = (initState: UIStringsState = defaultUIStrings) => {
  return createStore<UIStringsState>()(() => initState);
};

const {StateProvider, useCurrentStore} = getStoreProvider('UiStringsStore', createUiStringsStore);

export const UiStringsStoreProvider = StateProvider;
export const useUiStrings = useCurrentStore;
