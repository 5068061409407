import {type ColorName} from '#/cms/schemas/theme-colors.schema';
import {type PepIconName} from '@calvium/pep-icons';
import * as PepIconSvg from '@calvium/pep-icons/dist/PepIconSvg';
import classNames from 'classnames';

type Props = {
  className?: string;
  iconName: PepIconName;
  strokeColor?: ColorName | 'inherit';
  strokeColorHover?: ColorName;
  strokeColorGroupHover?: ColorName;
  size?: string;
  strokeWidth?: '1' | '1.5' | '2';
};

export const PepIcon = (props: Props) => {
  const {
    className,
    iconName,
    strokeColor = 'primary',
    strokeColorHover,
    strokeColorGroupHover,
    size = 'w-4',
    strokeWidth = '1.5',
  } = props;

  const Icon = PepIconSvg[iconName] as JSX.ElementType;
  if (!Icon) return null;
  return (
    <Icon
      className={classNames(
        size,
        `stroke-${strokeColor}`,
        `hover:stroke-${strokeColorHover}`,
        `group-hover:stroke-${strokeColorGroupHover}`,
        `stroke-${strokeWidth}`,
        className
      )}
    />
  );
};

export const PepIconSpacer = (props: Pick<Props, 'className' | 'size'>) => {
  const {className, size = 'w-4'} = props;
  return <div className={classNames(size, className)} />;
};
