'use client';

import {useRouter} from 'next/navigation';
import {useCallback, type ComponentProps, type MouseEventHandler} from 'react';

type Props = ComponentProps<'button'> & {
  href?: string;
};

const NavigableButton = ({href, onClick, ...props}: Props) => {
  const router = useRouter();
  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    e => {
      if (onClick) return onClick(e);
      if (href) return router.push(href);
    },
    [href, onClick, router]
  );
  return <button onClick={handleClick} {...props} />;
};

export default NavigableButton;
