'use client';
import classNames from 'classnames';
import {type ComponentProps} from 'react';
import NavigableButton from './NavigableButton';

type Props = ComponentProps<typeof NavigableButton>;

const Button = ({className, ...props}: Props) => {
  return (
    <NavigableButton
      className={classNames(
        'rounded-full border-2 border-primary bg-background p-2 text-small text-foreground hover:border-secondary',
        className
      )}
      {...props}
    />
  );
};

export default Button;
