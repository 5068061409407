'use client';

import {type FeatureFlags} from '#/cms/schemas/base-package.schema';
import {type PlaceSummary} from '#/cms/schemas/place-summary.schema';
import {type PublishType} from '#/cms/schemas/publish-type.schema';
import {type UIStrings} from '#/cms/schemas/uiStrings.schema';
import {type ReactNode} from 'react';
import {ClientEnvStoreProvider} from '../store/clientEnvStore';
import {FeatureFlagStoreProvider} from '../store/featureFlagsStore';
import {PlaceStoreProvider} from '../store/placeStore';
import {UiStringsStoreProvider} from '../store/uiStrings';

import {type WebAppInfo, type WebMobileAppPromo} from '#/cms/schemas/content.schema';
import {type ThemeColors} from '#/cms/schemas/theme-colors.schema';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {type CategoriesState, CategoriesStoreProvider} from '../store/categoriesStore';
import {useThemeStore} from '../store/themeColorsStore';
import {WebAppInfoStoreProvider} from '../store/webAppInfoStore';
import {WebMobileAppPromoStoreProvider} from '#/features/store/WebMobileAppPromoStore';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/London');

type Props = {
  children: ReactNode;
  currentMode: PublishType;
  currentVersion: string;
  featureFlags: FeatureFlags;
  fileUrl: string;
  baseUrl: string;
  googleMapsApiKey: string;
  placeMap: Map<string, PlaceSummary>;
  themeColors: ThemeColors;
  uiStrings: UIStrings;
  webAppInfo: WebAppInfo;
  webMobileAppPromo: WebMobileAppPromo;
  categories: CategoriesState;
};

export default function StoreInitializer({
  children,
  currentMode,
  currentVersion,
  featureFlags,
  fileUrl,
  baseUrl,
  googleMapsApiKey,
  placeMap,
  themeColors,
  uiStrings,
  webAppInfo,
  webMobileAppPromo,
  categories,
}: Props) {
  if (themeColors) useThemeStore.setState({themeColors});

  return (
    <FeatureFlagStoreProvider state={{featureFlags}}>
      <ClientEnvStoreProvider
        state={{
          currentVersion,
          fileUrl,
          googleMapsApiKey,
          currentMode,
          baseUrl,
        }}
      >
        <WebAppInfoStoreProvider state={webAppInfo}>
          <WebMobileAppPromoStoreProvider state={webMobileAppPromo}>
            <CategoriesStoreProvider state={categories}>
              <UiStringsStoreProvider state={uiStrings}>
                <PlaceStoreProvider state={{placeMap}}>{children}</PlaceStoreProvider>
              </UiStringsStoreProvider>
            </CategoriesStoreProvider>
          </WebMobileAppPromoStoreProvider>
        </WebAppInfoStoreProvider>
      </ClientEnvStoreProvider>
    </FeatureFlagStoreProvider>
  );
}
