import {z} from 'zod';

/**
 * Link schema.
 * This is used as a base for other category schemas.
 */
export const LinkSchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  image: z.string().optional(),
  websiteLabel: z.string().optional(),
  websiteUrl: z.string().optional(),
  websiteBrowserType: z.string().optional(),
});

export type Link = z.infer<typeof LinkSchema>;
