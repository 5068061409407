import * as z from 'zod';

export const MoreSectionSchema = z.object({
  id: z.string(),
  '(ordering)': z
    .string()
    .transform(val => (isNaN(+val) ? undefined : val))
    .optional(),
  title: z.string().optional(),
  link: z.string().url().optional(),
  image: z.string().optional(),
  body: z.string().optional(),
  displayInWebApp: z.boolean().optional(),
  displayInMobileApp: z.boolean().optional(),
});

export type MoreSection = z.infer<typeof MoreSectionSchema>;
