'use client';

import {useEffect, type ComponentProps} from 'react';
import {useSidebarStore} from '../store/sidebarStore';
import classNames from 'classnames';
import useDevice from '#/utils/useDevice';

const MainContent = ({className, ...props}: ComponentProps<'div'>) => {
  const {collapsed, setCollapsed} = useSidebarStore();
  const {isTablet, isDesktop} = useDevice();

  useEffect(() => {
    if (isTablet) setCollapsed(true);
    if (isDesktop) setCollapsed(false);
  }, [isDesktop, isTablet, setCollapsed]);

  return (
    <div
      id="main-content"
      tabIndex={-1}
      className={classNames(
        'flex grow transition-all duration-300 ease-in-out',
        collapsed ? 'ml-20' : 'ml-64',
        'mobile:ml-0',
        className
      )}
      {...props}
    />
  );
};

export default MainContent;
