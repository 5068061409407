import {z} from 'zod';
import {type Coordinate, CoordinateSchema} from './coordinate.schema';

export const PlaceSummarySchema = z.object({
  id: z.string(),
  '(ordering)': z.string().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  description: z.string().optional(),
  additionalInfoDescription: z.string().optional(),
  slideSetId: z.string().optional(),
  region: CoordinateSchema.array().optional(),
  regionBuffer: CoordinateSchema.array().optional(),
  coverImage: z.string().optional(),
  coverImageAlt: z.string().optional(),
  mapMarkerImage: z.string().optional(),
  logoImage: z.string().optional(),
  logoImageAlt: z.string().optional(),
  includeInList: z.boolean().optional(),
  showOnMap: z.boolean().optional(),
  includeInSearch: z.boolean().optional(),
  kiosks: z.unknown(),
  availableLanguageIds: z.string().array(),
});

export type PlaceSummary = z.infer<typeof PlaceSummarySchema> & {
  centroid?: Coordinate; // Calculated during parsing, before being inserted on Redis
};
