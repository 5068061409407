import {type ColorName} from '#/cms/schemas/theme-colors.schema';
import classNames from 'classnames';
import {type ComponentProps} from 'react';

type Heading1Props = ComponentProps<'h1'> & {className?: string};
export function Heading1({className, ...props}: Heading1Props) {
  return <h1 className={classNames('text-h1 font-bold leading-none text-foreground', className)} {...props} />;
}

export function Heading1BoldFirstWord({className, ...props}: Heading1Props) {
  const words = props.children?.toString().split(' ');
  const firstWord = words?.shift();
  const rest = words?.join(' ');
  return (
    <h1 className={classNames('text-h1 font-light leading-none text-foreground', className)} {...props}>
      <span className="font-bold">{firstWord}</span> {rest}
    </h1>
  );
}

type Heading2Props = ComponentProps<'h2'> & {className?: string; color?: ColorName};
export function Heading2({className, color = 'foreground', ...props}: Heading2Props) {
  return <h2 className={classNames(`text-h2 font-bold text-${color}`, className)} {...props} />;
}

type Heading3Props = ComponentProps<'h3'> & {className?: string; color?: ColorName};
export function Heading3({className, color = 'foreground', ...props}: Heading3Props) {
  return <h3 className={classNames(`text-h3 font-bold text-${color}`, className)} {...props} />;
}

type BodyTextProps = ComponentProps<'p'> & {className?: string; color?: ColorName};
export function BodyText({className, color = 'foreground', ...props}: BodyTextProps) {
  return <p className={classNames(`text-body tracking-[0.01rem] text-${color}`, className)} {...props} />;
}

type SmallTextProps = ComponentProps<'p'> & {
  className?: string;
  color?: ColorName;
  thin?: boolean;
};
export function SmallText({className, color = 'foreground', thin, ...props}: SmallTextProps) {
  return <p className={classNames('text-small', `text-${color}`, thin && 'font-thin', className)} {...props} />;
}
