import classNames from 'classnames';
import {type ComponentProps} from 'react';

const Box = ({className, ...props}: ComponentProps<'div'>) => {
  return (
    <div
      className={classNames('flex flex-col rounded-2xl border border-midground3 px-[17px] py-[13px]', className)}
      {...props}
    />
  );
};

export default Box;
