'use client';

import {useState, type ComponentProps, useCallback, useEffect} from 'react';
import classNames from 'classnames';
import {PepIcon} from './PepIcon';
import {Transition} from '@headlessui/react';
import Sidebar from './Sidebar';
import Image from 'next/image';
import useToggleScroll from '#/utils/useToggleScroll';
import {usePathname} from 'next/navigation';
import {useAppLogo} from '../store/webAppInfoStore';

type Props = ComponentProps<'div'> & {title: string | undefined};

const MobileNavbar = ({className, title, ...props}: Props) => {
  const pathname = usePathname();
  const toggleScroll = useToggleScroll();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = useCallback(() => {
    setSidebarOpen(v => !v);
    toggleScroll();
  }, [toggleScroll]);
  const {logoAlt, logoUrl} = useAppLogo();

  useEffect(() => {
    if (pathname && sidebarOpen) toggleSidebar();
    // We only want to listen pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <div
        className={classNames('grid h-20 w-full grid-cols-[1fr_auto_1fr] border-b border-midground3', className)}
        {...props}
      >
        <div className="col-start-1 flex flex-col justify-center">
          <button
            aria-label={`${sidebarOpen ? 'collapse' : 'expand'} sidebar`}
            className="ml-5 w-fit rounded-lg border border-midground3 p-2"
            onClick={toggleSidebar}
          >
            <PepIcon iconName={sidebarOpen ? 'Remove' : 'Menu'} strokeColor={'foreground'} />
          </button>
        </div>
        <div className="col-start-2 flex items-center">
          {logoUrl && <Image src={logoUrl} alt={logoAlt} width={32} height={32} />}
          {!!title ? <p className={'ml-3 text-2xl font-bold leading-none text-foreground'}>{title}</p> : null}
        </div>
        <div className="col-start-3"></div>
      </div>
      <Transition
        className="relative z-50"
        show={sidebarOpen}
        enter="ease-out duration-300"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="ease-in duration-200"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <Sidebar isMobile={true} />
      </Transition>
    </>
  );
};

export default MobileNavbar;
