import * as z from 'zod';
import {UIStringsSchema} from './uiStrings.schema';
import {env} from 'process';
import {AmenityCategorySchema} from './amenity-category.schema';
import {CategoryClassSchema} from './category-class.schema';
import {CreditSchema} from './credit.schema';
import {EventCategorySchema} from './event-category.schema';
import {PlaceSummarySchema} from './place-summary.schema';
import {SightCategorySchema} from './sight-category.schema';
import {MoreSectionSchema} from './more-sections.schema';
import {CoordinateSchema} from './coordinate.schema';
import {LinkSchema} from './link.schema';

const languageCodeSchema = z.string().min(2);

const AppInfoSchema = z.object({
  title: z.string(),
  description: z.string(),
  image: z.string(),
  projectLogo: z.string(),
});

export const WebAppInfoSchema = z.object({
  sideMenuTitle: z.string().optional(),
  logo: z.string().optional(),
  logoAlt: z.string().optional().default('Application logo'),
  homeTitle: z.string().optional(),
  homeSubtitle: z.string().optional(),
  radius: z.number().optional(),
  defaultLocation: CoordinateSchema.optional(),
  coverImage1: z.string().optional(),
  coverImage1Title: z.string().optional(),
  coverImage2: z.string().optional(),
  coverImage2Title: z.string().optional(),
  coverImage3: z.string().optional(),
  coverImage3Title: z.string().optional(),
  homeLinks: z
    .array(
      z.object({
        id: z.string(),
        collectionId: z.string(),
      })
    )
    .optional(),
  metadataAppTitle: z.string().optional(),
  metadataAppDescription: z.string().optional(),
  metadataTwitterHandle: z.string().optional(),
  trackingScript: z.string().optional(),
});

export const WebMobileAppPromoSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  coverImage: z.string().optional(),
  coverImageAlt: z.string().optional().default('Mobile app cover image'),
  appLogo: z.string().optional(),
  appLogoAlt: z.string().optional().default('Mobile app logo'),
  GooglePlayUrlLabel: z.string().optional(),
  GooglePlayUrl: z.string().optional(),
  AppStoreUrlLabel: z.string().optional(),
  AppStoreUrl: z.string().optional(),
});

const FeedbackSchema = z.object({
  feedbackType: z.enum(['email', 'formLink']).optional(),
  formLinkUrl: z.string().url().optional(),
  feedbackEmail: z.string().email().optional(),
});

const PartialAppInfoSchema = AppInfoSchema.partial();
export const ContentSchema = z.intersection(
  z.record(
    languageCodeSchema,
    z.object({
      categoryClasses: z.record(CategoryClassSchema).optional(),
      sightCategories: z.record(SightCategorySchema).optional(),
      amenityCategories: z.record(AmenityCategorySchema).optional(),
      eventCategories: z.record(EventCategorySchema).optional(),
      placeSummaries: z.record(PlaceSummarySchema),
      webUIStrings: UIStringsSchema,
      appInfo: PartialAppInfoSchema.optional(),
      webAppInfo: WebAppInfoSchema.optional(),
      webMobileAppPromo: WebMobileAppPromoSchema.optional(),
      credits: z.record(CreditSchema),
      links: z.record(LinkSchema).optional(),
      moreSections: z.record(MoreSectionSchema),
      feedback: FeedbackSchema.optional(),
    })
  ),
  // Must have app details for the default language
  z.union([
    z.record(z.literal(env.DEFAULT_LANGUAGE_CODE), AppInfoSchema),
    z.record(languageCodeSchema, PartialAppInfoSchema),
  ])
);

export type BasePackageContent = z.infer<typeof ContentSchema>[string];
export type LanguageCode = z.infer<typeof languageCodeSchema>;
export type AppInfo = z.infer<typeof AppInfoSchema>;
export type WebAppInfo = z.infer<typeof WebAppInfoSchema>;
export type WebMobileAppPromo = z.infer<typeof WebMobileAppPromoSchema>;
export type FeedbackInfo = z.infer<typeof FeedbackSchema>;
