'use client';

import {type PublishType} from '#/cms/schemas/publish-type.schema';
import {createStore} from 'zustand';
import getStoreProvider from './getStoreProvider';

type ClientEnvStore = {
  currentVersion: string;
  fileUrl: string;
  baseUrl: string;
  googleMapsApiKey: string;
  currentMode: PublishType;
};

const defaultInitState: ClientEnvStore = {
  currentVersion: 'unknown',
  fileUrl: '',
  baseUrl: '',
  googleMapsApiKey: '',
  currentMode: 'production',
};

const createClientEnvStore = (initState: ClientEnvStore = defaultInitState) => {
  return createStore<ClientEnvStore>()(() => initState);
};

const {StateProvider, useCurrentStore} = getStoreProvider('ClientEnvStore', createClientEnvStore);

export const ClientEnvStoreProvider = StateProvider;
export const useClientEnvStore = useCurrentStore;

export const useFileUrl = (fileName: string | undefined) => {
  const {fileUrl} = useClientEnvStore();
  if (fileName) return `${fileUrl}/${fileName}`;
  return '';
};
export const useBaseUrl = () => {
  const {baseUrl} = useClientEnvStore();
  return baseUrl ?? '';
};

export const useGoogleMapsApiKey = () => {
  const {googleMapsApiKey} = useClientEnvStore();
  return googleMapsApiKey || '';
};

export const useCurrentVersion = () => {
  const {currentVersion} = useClientEnvStore();
  return currentVersion;
};

export const useCurrentMode = () => {
  const {currentMode} = useClientEnvStore();
  return currentMode;
};
