'use client';

import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {useMemo} from 'react';
import React from 'react';

type Props = {
  apiKey: string | undefined;
  stage: string | undefined;
  version: string;
  children: React.ReactNode;
};

const BugsnagErrorBoundary = ({apiKey, stage, version, children}: Props) => {
  if (stage === 'local') console.log('Local Bugsnag env detected, reporting is omitted.');
  else {
    if (!Bugsnag.isStarted() && apiKey) {
      Bugsnag.start({apiKey, plugins: [new BugsnagPluginReact()], releaseStage: stage, appVersion: version});
      BugsnagPerformance.start({apiKey});
    }
  }

  const ErrorBoundary = useMemo(() => Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment, []);
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default BugsnagErrorBoundary;
