import {useCallback, useEffect, useState} from 'react';

const MOBILE_X_RES_BREAKPOINT = 768;
const TABLET_X_RES_BREAKPOINT = 1024;

// Do not use this hook for conditional rendering,
// prefer mobile-only and desktop-only css utility classes

const useDevice = () => {
  const [width, setWidth] = useState(Infinity);
  const handleResize = useCallback(() => setWidth(window.innerWidth), []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const isMobile = width <= MOBILE_X_RES_BREAKPOINT;
  const isTablet = !isMobile && width <= TABLET_X_RES_BREAKPOINT;
  const isDesktop = !isMobile && !isTablet;

  return {isMobile, isTablet, isDesktop};
};

export default useDevice;
