import {type ColorName, type ThemeColorsVariant} from '#/cms/schemas/theme-colors.schema';

type ActiveState = 'active' | 'inactive';
type EnabledState = 'enabled' | 'disabled';
type StateColors = {
  [themeColorsVariant in ThemeColorsVariant]: {
    [activeState in ActiveState]: {
      [enabledState in EnabledState]: ColorName;
    };
  };
};

const textStateColors: StateColors = {
  light: {
    active: {
      enabled: 'background',
      disabled: 'midground3',
    },
    inactive: {
      enabled: 'foreground',
      disabled: 'midground3',
    },
  },
  dark: {
    active: {
      enabled: 'foreground',
      disabled: 'midground3',
    },
    inactive: {
      enabled: 'foreground',
      disabled: 'midground3',
    },
  },
};

const iconStateColors: StateColors = {
  light: {
    active: {
      enabled: 'background',
      disabled: 'midground3',
    },
    inactive: {
      enabled: 'primary',
      disabled: 'midground3',
    },
  },
  dark: {
    active: {
      enabled: 'foreground',
      disabled: 'midground3',
    },
    inactive: {
      enabled: 'primary',
      disabled: 'midground3',
    },
  },
};

export const getTextColor = (
  themeColorsVariant: ThemeColorsVariant,
  active: boolean | undefined,
  enabled: boolean | undefined
) => {
  const textColor =
    textStateColors[themeColorsVariant][active ? 'active' : 'inactive'][enabled ? 'enabled' : 'disabled'] ??
    'foreground';
  return textColor;
};

export const getIconColor = (
  themeColorsVariant: ThemeColorsVariant,
  active: boolean | undefined,
  enabled: boolean | undefined
) => {
  const iconColor =
    iconStateColors[themeColorsVariant][active ? 'active' : 'inactive'][enabled ? 'enabled' : 'disabled'] ?? 'primary';
  return iconColor;
};
