import {create} from 'zustand';

type SidebarState = {
  collapsed: boolean;
  toggle: () => void;
  setCollapsed: (collapsed: boolean) => void;
};

export const useSidebarStore = create<SidebarState>((set, get) => ({
  collapsed: false,
  toggle: () => {
    const {collapsed} = get();
    set({collapsed: !collapsed});
  },
  setCollapsed: collapsed => {
    set({collapsed});
  },
}));
