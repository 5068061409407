export default {
  home: '/',
  search: '/search',
  sights: '/sights',
  events: '/events',
  places: '/places',
  mobileapp: '/mobileapp',
  more: '/more',
  accessibility: '/accessibility',
};

export const urlGenerators = {
  sight: (idOrSlug: string) => `/sights/${idOrSlug}`,
  event: (idOrSlug: string) => `/events/${idOrSlug}`,
  eventSight: (eventIdOrSlug: string, sightIdOrSlug: string) => `/events/${eventIdOrSlug}/${sightIdOrSlug}`,
};
