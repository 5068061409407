'use client';

import {usePathname} from 'next/navigation';
import {useEffect, type ReactNode} from 'react';
import useAnalytics from './useAnalytics';

type Props = {
  children: ReactNode;
  measurementId: string | undefined;
};

const Analytics = ({children, measurementId}: Props) => {
  const {initializeAnalytics, registerPageView} = useAnalytics();
  const pathname = usePathname();

  if (measurementId) initializeAnalytics(measurementId);

  useEffect(() => {
    registerPageView(pathname);
  }, [pathname, registerPageView]);

  return children;
};

export default Analytics;
