'use client';

import {type ReactNode, createContext, useRef, useContext, useCallback} from 'react';
import {useStore, type StoreApi} from 'zustand';

const getStoreProvider = <T,>(name: string, createStore: (initState?: T) => StoreApi<T>) => {
  const CurrentContext = createContext<StoreApi<T> | null>(null);

  interface StateProviderProps {
    state: T;
    children: ReactNode;
  }

  const StateProvider = ({state, children}: StateProviderProps) => {
    const storeRef = useRef<StoreApi<T>>();
    if (!storeRef.current) {
      storeRef.current = createStore(state);
    }

    return <CurrentContext.Provider value={storeRef.current}>{children}</CurrentContext.Provider>;
  };

  const useCurrentStore = () => {
    const context = useContext(CurrentContext);
    const emptySelector = useCallback<(store: T) => T>(store => store, []);

    if (!context) {
      throw new Error(`useCurrentStore hook from "${name}" store must be use within its provider`);
    }

    return useStore(context, emptySelector);
  };

  return {StateProvider, useCurrentStore};
};

export default getStoreProvider;
