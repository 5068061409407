import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lato\",\"arguments\":[{\"display\":\"swap\",\"preload\":true,\"subsets\":[\"latin-ext\"],\"weight\":[\"300\",\"400\",\"700\"]}],\"variableName\":\"lato\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/analytics/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/components/BugsnagErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/components/CookieConsentModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/components/MainContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/components/MobileNavbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/components/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/components/StoreInitializer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Theme"] */ "/app/src/features/components/Theme.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
