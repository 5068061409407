'use client';

import {createStore} from 'zustand';
import {useFileUrl} from './clientEnvStore';
import {type WebMobileAppPromo} from '#/cms/schemas/content.schema';
import getStoreProvider from './getStoreProvider';

const createWebMobileAppPromoStore = (
  initState: WebMobileAppPromo = {
    coverImage: '',
    coverImageAlt: '',
    appLogo: '',
    appLogoAlt: '',
  }
) => {
  return createStore<WebMobileAppPromo>()(() => initState);
};

const {StateProvider, useCurrentStore} = getStoreProvider('WebMobileAppPromoStore', createWebMobileAppPromoStore);

export const WebMobileAppPromoStoreProvider = StateProvider;
export const useWebMobileAppPromoStore = useCurrentStore;

export const useMobileAppLogo = (): {mobileAppLogo: string; mobileAppLogoAlt: string} => {
  const {appLogo, appLogoAlt} = useWebMobileAppPromoStore();
  const mobileAppLogo = useFileUrl(appLogo);
  return {mobileAppLogo, mobileAppLogoAlt: appLogoAlt};
};
