'use client';
import Button from './Button';
import {SmallText} from '#/features/components/Text';
import Image from 'next/image';
import {useSidebarStore} from '#/features/store/sidebarStore';
import {useUiStrings} from '../store/uiStrings';
import classNames from 'classnames';
import {usePathname} from 'next/navigation';

type MobileAppButtonProps = {
  href: string;
  label?: string;
  logoUrl?: string;
  logoAlt: string;
};

export default function MobileAppButton({href, label, logoAlt, logoUrl}: MobileAppButtonProps) {
  const {collapsed} = useSidebarStore();
  const uiStrings = useUiStrings();
  const pathname = usePathname();

  // Have to apply important as the order of the styles is not guaranteed, other possible solutions:
  // See https://stackoverflow.com/a/76766092/3982685
  // Possible solution: https://github.com/mwnciau/tailwindcss-unimportant
  return (
    <Button
      aria-label={uiStrings.general.mobileAppButtonLabel}
      title={collapsed ? uiStrings.general.mobileAppButtonLabel : undefined}
      href={href}
      className={classNames(
        'flex flex-row items-center bg-background',
        collapsed ? '!rounded-2xl' : 'h-[4.75rem] !rounded-3xl',
        pathname === href ? 'bg-primary' : ''
      )}
      type="button"
    >
      {logoUrl && (
        <div className={`relative aspect-square ${collapsed ? 'w-8' : 'm-2 w-16'}`}>
          <Image
            className="rounded-lg object-cover"
            src={logoUrl}
            alt={logoAlt}
            fill
            aria-hidden="true"
            role="presentation"
          />
        </div>
      )}
      {!collapsed && (
        <SmallText
          className={classNames('px-2 text-left', pathname === href ? '!text-background' : '!text-foreground')}
        >
          {label}
        </SmallText>
      )}
    </Button>
  );
}
