'use client';
import {type AmenityCategory} from '#/cms/schemas/amenity-category.schema';
import {type EventCategory} from '#/cms/schemas/event-category.schema';
import {type SightCategory} from '#/cms/schemas/sight-category.schema';
import {createStore} from 'zustand';
import getStoreProvider from './getStoreProvider';

export type CategoriesState = {
  eventCategories: Map<string, EventCategory>;
  sightCategories: Map<string, SightCategory>;
  amenityCategories: Map<string, AmenityCategory>;
};

const createCategoriesStore = (
  initState: CategoriesState = {
    eventCategories: new Map(),
    sightCategories: new Map(),
    amenityCategories: new Map(),
  }
) => {
  return createStore<CategoriesState>(() => initState);
};

const {StateProvider, useCurrentStore} = getStoreProvider('CategoriesStore', createCategoriesStore);

export const CategoriesStoreProvider = StateProvider;
export const useCategoriesStore = useCurrentStore;
