import {type PepIconName} from '@calvium/pep-icons';
import {z} from 'zod';

export const CategoryClassSchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  pepIcon: z.string().optional(),
});

export type CategoryClass = z.infer<typeof CategoryClassSchema> & {
  pepIcon?: PepIconName;
};
