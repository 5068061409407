import {useCallback, useEffect, useState} from 'react';

const useToggleScroll = () => {
  const [scrollEnabled, setScrollEnabled] = useState(true);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      if (!scrollEnabled) {
        event.preventDefault();
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (!scrollEnabled) {
        event.preventDefault();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener('wheel', handleScroll, {passive: false});
      document.addEventListener('touchmove', handleTouchMove, {passive: false});
    }

    return () => {
      document.removeEventListener('wheel', handleScroll);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [scrollEnabled]);

  const toggleScroll = useCallback(() => setScrollEnabled(prevScrollEnabled => !prevScrollEnabled), []);
  return toggleScroll;
};

export default useToggleScroll;
