import {create} from 'zustand';
import {type ThemeColorsVariant, type ThemeColors} from './../../cms/schemas/theme-colors.schema';
import {persist} from 'zustand/middleware';
import Bugsnag from '@bugsnag/js';

type ThemeState = {
  _initialised: boolean;
  themeColorsVariant: ThemeColorsVariant;
  themeColors: ThemeColors;
  initialise: (themeColorsVariant: ThemeColorsVariant) => void;
};

export const useThemeStore = create<ThemeState>()(
  persist(
    set => ({
      _initialised: false,
      themeColorsVariant: 'light',
      themeColors: {
        light: {
          primary: '#000000',
          secondary: '#000000',
          background: '#000000',
          foreground: '#000000',
          midground1: '#000000',
          midground2: '#000000',
          midground3: '#000000',
          midground4: '#000000',
          light: '#000000',
          dark: '#000000',
          modalBackdrop: '#000000',
          success: '#000000',
          danger: '#000000',
        },
        dark: {
          primary: '#000000',
          secondary: '#000000',
          background: '#000000',
          foreground: '#000000',
          midground1: '#000000',
          midground2: '#000000',
          midground3: '#000000',
          midground4: '#000000',
          light: '#000000',
          dark: '#000000',
          modalBackdrop: '#000000',
          success: '#000000',
          danger: '#000000',
        },
      },
      initialise: themeColorsVariant => {
        set({themeColorsVariant, _initialised: true});
      },
    }),
    {
      name: 'theme',
      onRehydrateStorage: () => {
        return (state, error) => {
          if (error) {
            console.log('an error happened during hydration', error);
            Bugsnag.notify(error as Error);
          } else {
            if (!state?._initialised) {
              state?.initialise(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
            }
          }
        };
      },
    }
  )
);

export const useCurrentThemeColors = () => {
  const theme = useThemeStore();
  const {themeColorsVariant, themeColors} = theme;
  return themeColors[themeColorsVariant];
};

export const useCurrentThemeVariant = () => {
  const theme = useThemeStore();
  return theme.themeColorsVariant;
};
