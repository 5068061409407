import {type PepIconName} from '@calvium/pep-icons';
import {z} from 'zod';
import {CategorySchema} from './category.schema';

export const EventCategorySchema = CategorySchema.extend({
  eventIds: z.string().array().optional(),
});

export type EventCategory = z.infer<typeof EventCategorySchema> & {
  pepIcon?: PepIconName;
};
